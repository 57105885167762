import React from "react";
import {
  Box,
  Typography,
  Link,
  Container,
  Paper,
} from "@mui/material";
import cautoImage from "../assets/cauto.jpeg";

const homescreenImg = {
  width: "100%",
  height: "100%",
  borderRadius: 6,
};

const AboutUs = () => {
  return (
    <Container id="aboutUs">
      <Paper
        elevation={6}
        sx={{ padding: 4, display: {xs: 'block', sm: 'flex'}, width: '100%' }}
      >
        <Box sx={{width: '80%', paddingRight: 4}}>
          <Typography variant="h3" component="h1" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body1" paragraph>
            At TechConsult, we specialize in delivering innovative IT solutions
            that drive business growth and operational efficiency. Our team of
            experienced consultants is dedicated to providing top-notch services
            tailored to meet the unique needs of each client.
          </Typography>

          <Typography variant="h3" component="h2" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" paragraph>
            Our mission is to empower businesses through technology. We strive
            to deliver customized IT solutions that enhance productivity,
            streamline operations, and provide a competitive edge.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            We would love to hear from you! Reach out to us for any inquiries or
            to discuss how we can help your business achieve its IT goals.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Email:</strong>{" "}
            <Link href="mailto:contact@cauto.in">
              contact@cauto.in
            </Link>
            <br />
            <strong>Phone:</strong> +91 97048 49321
            <br />
            <strong>Address:</strong> Gondriyala, Hyderbad City, Telangana, India 508206
          </Typography>
        </Box>
        <Box>
          <img
            src={cautoImage}
            style={homescreenImg}
            alt=" home screen for cauto"
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default AboutUs;
