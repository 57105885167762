import React from "react";
import Typography from "@mui/material/Typography";
import manHomeImg from "../assets/manCloudHdAspect.jpg";
import Box from "@mui/material/Box";

const imageStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
};

const Home = () => {
  return (
    <Box sx={{ position: "relative", top: 80, left: 0, right: 0 }} id="home">
      <img src={manHomeImg} style={imageStyle} alt="home page logo" />
      <Typography
        variant="h4"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          fontSize: "clamp(3.5rem, 10vw, 4rem)",
          color: "white",
          textShadow: " 2px 2px #666666",
          position: "absolute",
          background: "linear-gradient(grey, transparent)",
          left: 0,
          bottom: 35,
        }}
      >
        Elevate Your Business with Seamless Cloud Automation: Efficiency,
        Simplify, Innovation at Your Fingertips
      </Typography>
    </Box>
  );
};

export default Home;
