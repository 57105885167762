import React from "react";
import Container from "@mui/material/Container";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import { Paper, Typography } from "@mui/material";

const styles = {
  cloudServices: {
    display: {xs: 'block', sm: 'flex'},
    justifyContent: "spaceAround",
    // backgroundColor: "White",
    // color: "white",
    padding: "20px",
    // border: "1px solid #bae6fd",
    borderRadius: "8px",
  },
  service: {
    flex: 1,
    margin: "0 10px",
  },
  icon: {
    fontSize: "40px",
    marginBottom: "10px",
  },
  h3: {
    marginBottom: "10px",
    fontSize: "24px",
  },
  p: {
    fontSize: "16px",
  },
};

const Services = () => {
  return (
    <Container id="services" sx={{ py: { xs: 8, sm: 16 } }}>
      <Paper elevation={6} sx={styles.cloudServices}>
        <div style={styles.service}>
          <div style={styles.icon}>
            <TrendingDownOutlinedIcon
              fontSize="large"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            />
          </div>
          <h1>Reduce risk</h1>
          <Typography variant="body1">
            Our cloud services are designed with integrated industry best
            practices, processes, and methodologies that are reinforced by 24-7
            support, that helps your service run smoothly, minimizes the risk of
            failure and drives your ongoing success.
          </Typography>
        </div>
        <div style={styles.service}>
          <div style={styles.icon}>
            <LockResetOutlinedIcon
              fontSize="large"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            />
          </div>
          <h1>Secure</h1>
          <Typography variant="body1">
            Our modern security and privacy measures safeguard your data and our
            time-tested processes coupled with our cloud governance, compliance,
            and visibility features provide unparalleled transparency and
            control over your IT environment from our user-friendly digital
            self-service model.
          </Typography>
        </div>
        <div style={styles.service}>
          <div style={styles.icon}>
            <ManageHistoryOutlinedIcon
              fontSize="large"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            />
          </div>
          <h1>Manage</h1>
          <Typography variant="body1">
            Prioritize your business objectives by entrusting the management of
            your IT estate to the Kyndryl experts. Our intelligent monitoring
            and pre-emptive management of hybrid multi-cloud environments allow
            you to optimize the potential of your cloud environment, giving you
            the freedom to focus on what truly matters.
          </Typography>
        </div>
      </Paper>
    </Container>
  );
};

export default Services;
